<h3 class="mb-4">Willkommen beim Incora Dashboard</h3>
<p class="mb-4">
	<span class="font-bold">Was ist Incora?</span> Incora steht für
	<a
		class="text-select"
		target="_blank"
		href="https://www.bmvi.de/SharedDocs/DE/Artikel/DG/mfund-projekte/incora.html"
		>Inwertsetzung von Copernicus für die Raumbeobachtung</a
	>, ein vom Bundesministerium für Digitales und Verkehr im Rahmen des Modernitätsfonds
	<a
		class="text-select"
		target="_blank"
		href="https://www.bmvi.de/DE/Themen/Digitales/mFund/Ueberblick/ueberblick.html">mFund</a
	> gefördertes Forschungsprojekt. Im Projekt wurde untersucht, inwiefern mit neuen Datenquellen aus
	Satelliten des europäischen Copernicus-Programms das Monitoring des Flächenverbrauchs in Deutschland
	verbessert werden kann.
</p>
<p class="mb-4">
	<span class="font-bold">Warum ist ein Monitoring des Flächenverbrauchs notwendig?</span> Die Bebauung
	und Versiegelung von Flächen geht auf Kosten wertvollen Bodens und dessen natürlicher Produktions-
	und Ausgleichsfunktionen für zahlreiche Umweltbelastungen. Die Art und Weise, wie die Menschheit ihre
	Siedlungssysteme anlegt und entwickelt, legt über Jahrzehnte das Ausmaß des Ressourcenaufwands fest,
	mit dem diese Siedlungssysteme bewohnt und bewirtschaftet werden. Der Flächenverbrauch ist somit eine
	maßgebliche Einflussgröße für nachhaltige Entwicklung und Klimaschutz mit besonderer Langzeitwirkung.
</p>
<p class="mb-4">
	<span class="font-bold">Was sind die Probleme beim Monitoring des Flächenverbrauchs?</span>
	Datengrundlagen zum Flächenverbrauch werden in Deutschland aus dem Liegenschaftskataster gewonnen.
	Das Statistische Bundesamt veröffentlicht daraus jährlich die
	<span class="italic">Bodennutzung nach Art der Tatsächlichen Nutzung</span> und berichtet über
	Veränderungen zum Vorjahr. Das Liegenschaftskataster unterliegt im Zeitverlauf allerdings
	Umstellungseffekten und ist nicht einheitlich aktuell. Alternativen aus der Erdbeobachtung, die
	unter anderem die Europäische Union zum Monitoring des Flächenverbrauchs nutzt, berichten über den
	Flächenverbrauch auf Basis von
	<span class="italic">Landbedeckungsklassen</span>. Brüche in der Kontinuität und Unterschiede in
	den Erfassungsmethoden führen zu widersprüchlichen Ergebnissen.
</p>
<p class="mb-4">
	<span class="font-bold">Was ist das Incora Dashboard?</span> Die interaktiven Karten und Diagramme
	stellen wissenschaftlich hergeleitete Kenngrößen zur Landbedeckung und zum Flächenverbrauch in Deutschland
	dar. Die Ergebnisse können mit unterschiedlichen Datenquellen angezeigt und verglichen werden. Die
	Visualisierung dieser Unterschiede leistet somit einen Beitrag, um die Auswirkungen des Flächenverbrauchs
	der vergangenen Jahre im Vergleich unterschiedlicher Datenquellen besser zu verstehen.
</p>
<p class="mb-4">
	<span class="font-bold">Was sind die zentralen Erkenntnisse und Perspektiven?</span>
	Die Ergebnisse aus dem Projekt unterstreichen den Bedarf an geeigneten Daten für ein stabiles und zukunftsfähiges
	Monitoring des Flächenverbrauchs. Potenziale liegen in der intelligenten Verknüpfung bestehender Datengrundlagen
	mit neu aufkommenden Technologien, um Stärken und Schwächen auszugleichen, und der niedrigschwelligen
	Bereitstellung und Visualisierung von Ergebnissen. Dringend erforderlich ist darüber hinaus die Einbindung
	digitaler Informationen zur geplanten Siedlungsentwicklung, um die Auswirkungen des Flächenverbrauchs
	vorausschauend abzumildern.
</p>
